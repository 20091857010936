import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';

import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Accessibility = ({
  data: {
    allAccessibility: { nodes },
  },
}) => {
  const sortedAccessibility = nodes.sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));
  return (
    <Layout pageTitle="Accessibility">
      <Header title="Accessibility" subtitle="Guides and tutorials for you to make your pages accessible" />
      <Ink.Box top="20" bottom="20">
        <Ink.Text>
          A disability is defined as a condition or function being significantly impaired when compared to the usual
          standard of an individual or group. People with disabilities make up 15% of the population and these
          disabilities can affect several different aspects of a person's life, from body functioning to social and
          environmental relationships.{' '}
          <Ink.Anchor href="https://www.disabled-world.com/disability/types/" preset="new-tab">
            Read more about disabilities
          </Ink.Anchor>
          .
        </Ink.Text>
        <Ink.Text>
          Accessibility, in the context of the web, means that everyone - including those with disabilities — can use
          and navigate any web-based product. This is a vast field that addressed the needs of all people and their
          unique needs. Some of the disabilities we have to have in mind when developing for the web are:
        </Ink.Text>
        <Ink.Text>
          <Ink.Ul>
            <Ink.Li>Visual disabilities such as: partial or total visual loss, color blindness, cataracts, etc;</Ink.Li>
            <Ink.Li>
              Mobility or physical disabilities such as loss of dexterity, limbs' disabilities, Parkinsons (shaky
              hands), etc;
            </Ink.Li>
            <Ink.Li>Partial or total hearing loss;</Ink.Li>
            <Ink.Li>Sensitivity to motion or blinking lights;</Ink.Li>
            <Ink.Li>Temporary disabilities like people with broken or sprained limbs, recent eye surgery, etc;</Ink.Li>
            <Ink.Li>
              Situational disabilities like glaring sun on phones' screens, using phones with one hand when the other is
              occupied, etc;
            </Ink.Li>
          </Ink.Ul>
        </Ink.Text>
        <Ink.Text>
          <Ink.Anchor href="https://www.microsoft.com/design/inclusive/" preset="new-tab">
            Learn more about Inclusive Design.
          </Ink.Anchor>
        </Ink.Text>
        <Ink.Text>
          By striving to make Carta an accessible product we're not only improving the Carta experience for all users
          but we're also strengthening the Carta brand of equity and inclusion. Not being accessible is also a legal
          liability to Carta as it could be a target of legal actions for not being compliant with the{' '}
          <Ink.Anchor href="https://ada.gov" preset="new-tab">
            Americans with Disabilities Act (ADA)
          </Ink.Anchor>
          or other countries' regulations as we expand internationally.
        </Ink.Text>
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedAccessibility.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View guide"
            actionHref={`/accessibility/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Accessibility;

export const query = graphql`
  query AccessibilityQuery {
    allAccessibility: allMdx(filter: { fileAbsolutePath: { regex: "/content/accessibility/" } }) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
